import React, { useState, useEffect, useCallback, useRef } from 'react';
import './App.css';

const BOARD_WIDTH = 10;
const BOARD_HEIGHT = 20;
const BLOCK_SIZE = 30;
const INITIAL_DROP_TIME = 1000;
const SPEED_INCREASE_RATE = 0.95;

const TETROMINOS = {
  I: { shape: [[1, 1, 1, 1]], color: '#00ffff' },
  J: { shape: [[1, 0, 0], [1, 1, 1]], color: '#0000ff' },
  L: { shape: [[0, 0, 1], [1, 1, 1]], color: '#ff7f00' },
  O: { shape: [[1, 1], [1, 1]], color: '#ffff00' },
  S: { shape: [[0, 1, 1], [1, 1, 0]], color: '#00ff00' },
  T: { shape: [[0, 1, 0], [1, 1, 1]], color: '#800080' },
  Z: { shape: [[1, 1, 0], [0, 1, 1]], color: '#ff0000' },
  U: { shape: [[1], [1], [1], [1], [1]], color: '#FFA500' },
};

const createBoard = () => Array.from({ length: BOARD_HEIGHT }, () => Array(BOARD_WIDTH).fill(0));

const BlockGame = () => {
  const [board, setBoard] = useState(createBoard());
  const [currentPiece, setCurrentPiece] = useState(null);
  const [nextPiece, setNextPiece] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [clearedLines, setClearedLines] = useState(0);
  const [playTime, setPlayTime] = useState(0);
  const [isShaking, setIsShaking] = useState(false);
  const [dropTime, setDropTime] = useState(INITIAL_DROP_TIME);
  const gameLoopRef = useRef(null);

  const getRandomTetromino = useCallback(() => {
    const tetrominos = Object.keys(TETROMINOS);
    const randTetromino = tetrominos[Math.floor(Math.random() * tetrominos.length)];
    return {
      pos: { x: Math.floor(BOARD_WIDTH / 2) - Math.floor(TETROMINOS[randTetromino].shape[0].length / 2), y: 0 },
      tetromino: TETROMINOS[randTetromino],
    };
  }, []);

  const isColliding = useCallback((piece, board) => {
    for (let y = 0; y < piece.tetromino.shape.length; y++) {
      for (let x = 0; x < piece.tetromino.shape[y].length; x++) {
        if (piece.tetromino.shape[y][x]) {
          if (
            board[y + piece.pos.y] === undefined ||
            board[y + piece.pos.y][x + piece.pos.x] === undefined ||
            board[y + piece.pos.y][x + piece.pos.x] !== 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }, []);

  const clearRows = useCallback((board) => {
    let clearedRows = 0;
    const newBoard = board.reduce((acc, row) => {
      if (row.every((cell) => cell !== 0)) {
        clearedRows++;
        acc.unshift(new Array(board[0].length).fill(0));
      } else {
        acc.push(row);
      }
      return acc;
    }, []);
    return [clearedRows, newBoard];
  }, []);

  const updateBoard = useCallback(() => {
    const newBoard = board.map((row) => [...row]);
    currentPiece.tetromino.shape.forEach((row, y) => {
      row.forEach((value, x) => {
        if (value !== 0) {
          newBoard[y + currentPiece.pos.y][x + currentPiece.pos.x] = currentPiece.tetromino.color;
        }
      });
    });
    const [clearedRows, updatedBoard] = clearRows(newBoard);
    setBoard(updatedBoard);
    setCurrentPiece(nextPiece);
    setNextPiece(getRandomTetromino());
    if (clearedRows > 0) {
      setScore((prev) => prev + clearedRows * 10);
      setClearedLines((prev) => {
        const newClearedLines = prev + clearedRows;
        setDropTime((prevDropTime) => prevDropTime * Math.pow(SPEED_INCREASE_RATE, clearedRows));
        return newClearedLines;
      });
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
    }
  }, [board, currentPiece, nextPiece, getRandomTetromino, clearRows]);

  const rotate = useCallback((piece, board) => {
    const rotated = piece.tetromino.shape[0].map((_, index) =>
      piece.tetromino.shape.map(row => row[index]).reverse()
    );
    const newPiece = {
      ...piece,
      tetromino: { ...piece.tetromino, shape: rotated },
    };

    if (!isColliding(newPiece, board)) {
      return newPiece;
    }
    return piece;
  }, [isColliding]);

  const movePlayer = useCallback((dir) => {
    if (!isColliding({ ...currentPiece, pos: { x: currentPiece.pos.x + dir, y: currentPiece.pos.y } }, board)) {
      setCurrentPiece((prev) => ({ ...prev, pos: { x: prev.pos.x + dir, y: prev.pos.y } }));
    }
  }, [currentPiece, board, isColliding]);

  const drop = useCallback(() => {
    if (!isColliding({ ...currentPiece, pos: { x: currentPiece.pos.x, y: currentPiece.pos.y + 1 } }, board)) {
      setCurrentPiece((prev) => ({ ...prev, pos: { x: prev.pos.x, y: prev.pos.y + 1 } }));
    } else {
      if (currentPiece.pos.y < 1) {
        setGameOver(true);
        return;
      }
      updateBoard();
    }
  }, [currentPiece, board, isColliding, updateBoard]);

  const formatTime = useCallback((seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }, []);

  const renderPiece = useCallback((piece, offsetX = 0, offsetY = 0) => (
    piece.tetromino.shape.map((row, y) =>
      row.map((cell, x) => {
        if (cell) {
          return (
            <div
              key={`piece-${y}-${x}`}
              style={{
                position: 'absolute',
                top: (piece.pos.y + y + offsetY) * BLOCK_SIZE,
                left: (piece.pos.x + x + offsetX) * BLOCK_SIZE,
                width: BLOCK_SIZE,
                height: BLOCK_SIZE,
                backgroundColor: piece.tetromino.color,
                border: '1px solid rgba(255, 255, 255, 0.5)',
              }}
            />
          );
        }
        return null;
      })
    )
  ), []);

  const handleTouch = useCallback((action) => {
    if (!gameOver) {
      if (action === 'left') movePlayer(-1);
      if (action === 'right') movePlayer(1);
      if (action === 'down') drop();
      if (action === 'rotate') setCurrentPiece((prev) => rotate(prev, board));
    }
  }, [gameOver, movePlayer, drop, rotate, board]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!gameOver) {
        if (e.keyCode === 37) movePlayer(-1);
        if (e.keyCode === 39) movePlayer(1);
        if (e.keyCode === 40) drop();
        if (e.keyCode === 38) setCurrentPiece((prev) => rotate(prev, board));
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [gameOver, movePlayer, drop, rotate, board]);

  useEffect(() => {
    if (!gameOver) {
      gameLoopRef.current = setInterval(drop, dropTime);
      return () => {
        if (gameLoopRef.current) {
          clearInterval(gameLoopRef.current);
        }
      };
    }
  }, [drop, gameOver, dropTime]);

  useEffect(() => {
    if (!gameOver) {
      const timer = setInterval(() => {
        setPlayTime((prev) => prev + 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [gameOver]);

  useEffect(() => {
    if (!currentPiece) {
      setCurrentPiece(getRandomTetromino());
    }
    if (!nextPiece) {
      setNextPiece(getRandomTetromino());
    }
  }, [currentPiece, nextPiece, getRandomTetromino]);

  const addMetaViewport = () => {
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'viewport');
    meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
    document.head.appendChild(meta);
  };

  useEffect(() => {
    addMetaViewport();
    return () => {
      document.head.querySelector('meta[name="viewport"]').remove();
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-purple-500 to-pink-500">
      <style jsx>{`
        @keyframes shake {
          0%, 100% { transform: translateX(0); }
          25% { transform: translateX(-5px); }
          75% { transform: translateX(5px); }
        }
        .shake {
          animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
        }
      `}</style>
      <h1 className="text-4xl font-bold text-white mb-4">ブロック消しゲーム</h1>
      <div className="flex flex-col">
        <div className="flex">
          <div
            className={`relative bg-gray-800 rounded-lg shadow-lg overflow-hidden ${isShaking ? 'shake' : ''}`}
            style={{
              width: BLOCK_SIZE * BOARD_WIDTH,
              height: BLOCK_SIZE * BOARD_HEIGHT,
            }}
          >
            {gameOver ? (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 z-10">
                <div className="text-white text-center">
                  <h2 className="text-3xl font-bold mb-4">Game Over</h2>
                  <p className="text-xl">Score: {score}</p>
                  <p className="text-xl">Cleared Lines: {clearedLines}</p>
                  <p className="text-xl">Play Time: {formatTime(playTime)}</p>
                  <button
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                    onClick={() => {
                      setBoard(createBoard());
                      setGameOver(false);
                      setScore(0);
                      setClearedLines(0);
                      setPlayTime(0);
                      setDropTime(INITIAL_DROP_TIME);
                      setCurrentPiece(getRandomTetromino());
                      setNextPiece(getRandomTetromino());
                    }}
                  >
                    Restart
                  </button>
                </div>
              </div>
            ) : null}
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
              }}
            >
              {board.map((row, y) =>
                row.map((cell, x) => (
                  <div
                    key={`${y}-${x}`}
                    style={{
                      position: 'absolute',
                      top: y * BLOCK_SIZE,
                      left: x * BLOCK_SIZE,
                      width: BLOCK_SIZE,
                      height: BLOCK_SIZE,
                      backgroundColor: cell || 'transparent',
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}
                  />
                ))
              )}
              {currentPiece && renderPiece(currentPiece)}
            </div>
          </div>
          <div className="ml-4 bg-gray-800 p-4 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-white mb-2">Next Piece</h2>
            <div
              style={{
                position: 'relative',
                width: BLOCK_SIZE * 4,
                height: BLOCK_SIZE * 4,
              }}
            >
              {nextPiece && renderPiece(nextPiece, -nextPiece.pos.x, -nextPiece.pos.y)}
            </div>
          </div>
        </div>
        <div className="mt-4 bg-gray-800 p-4 rounded-lg shadow-lg text-white text-xl font-bold">
          <p>Score: {score}</p>
          <p>Cleared Lines: {clearedLines}</p>
          <p>Play Time: {formatTime(playTime)}</p>
          <p>Drop Speed: {(INITIAL_DROP_TIME / dropTime).toFixed(2)}x</p>
        </div>
      </div>
      <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 md:hidden">
        <button
          className="bg-blue-500 text-white p-2 rounded"
          onClick={() => handleTouch('left')}
        >
          左移動
        </button>
        <button
          className="bg-blue-500 text-white p-2 rounded"
          onClick={() => handleTouch('rotate')}
        >
          回転
        </button>
        <button
          className="bg-blue-500 text-white p-2 rounded"
          onClick={() => handleTouch('right')}
        >
          右移動
        </button>
        <button
          className="bg-blue-500 text-white p-2 rounded"
          onClick={() => handleTouch('down')}
        >
          下移動
        </button>
      </div>
    </div>
  );
};

export default BlockGame;